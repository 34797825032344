
//@ts-nocheck
import {Component} from 'vue-property-decorator'
import GenericListComponent from "@/components/generic/GenericListComponent.vue";
import {DataTableHeader} from "vuetify";
import SchoolClass, {SchoolClassWrapper} from "@/assets/Entities/SchoolClass";
import ClassEditor from "@/components/class/ClassEditor.vue";
import {TargetType} from "@/assets/constants/Enums";
import {SchoolURLParams} from "@/assets/service/Endpoints";

@Component({
  components: {ClassEditor}
})
export default class ClassList extends GenericListComponent<SchoolClass, SchoolClassWrapper> {
  created() {
    this.loadItems();
  }

  headers: DataTableHeader[] = [
    {
      text: this.$t('Class.List.Table.Header1').toString(),
      value: 'name',
      width: '60%'
    },
    {
      text: this.$t('Class.List.Table.Header2').toString(),
      value: 'count',
      width: '30%'
    },
    {
      text: this.$t('Class.List.Table.Header3').toString(),
      value: 'action',
      sortable: false,
      align: 'center',
      width: '10%'
    }];

  get params(): SchoolURLParams {
    return {
      type: TargetType.DEPARTMENT,
      client: this.$route.params.client
    }
  }

  checkBoxLabel: string = 'Class.List.InactiveUsers';

  showInactive: boolean = false;

  title: string = "Class.List.Title";
  addButtonTitle: string = "Class.List.AddClass";
  addButtonIcon: string = "mdi-plus";
  elementActionIcon: string = "mdi-pencil";
  dialog: string = "class-editor";
  rowsPerPageText: string = 'Class.RowsPerPage';
  canBeInactive = false;
}
