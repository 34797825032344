
//@ts-nocheck
import {Component} from 'vue-property-decorator'
import GenericEntityEditor from "@/components/generic/GenericEntityEditor.vue";
import SchoolClass from "@/assets/Entities/SchoolClass";
import {InputField} from "@/components/general/InputComp.vue";
import {RequestType, TargetType, UserType} from "@/assets/constants/Enums";
import {Action, SchoolURLParams} from "@/assets/service/Endpoints";
import User from "@/assets/Entities/User";
import InputListComponent from "@/components/generic/InputListComponent.vue";
import Webservice from "@/assets/service/Webservice";

@Component({
  components: {InputListComponent}
})
export default class ClassEditor extends GenericEntityEditor<SchoolClass> {
  openDialog = false;
  showSafetyDialog = false;
  title: string = 'Class.Dialog.Title.';
  pupil: User[] = [];
  schoolClassId: string = '';

  get params(): SchoolURLParams {
    return {
      type: TargetType.DEPARTMENT,
      client: this.$route.params.client,
      departmentId: this.model?._id
    }
  }

  createCleanEntity(): SchoolClass {
    return new SchoolClass();
  }

  get fields(): InputField[] {
    let fields: InputField[] = [{
      title: 'Class.Dialog.Name',
      value: 'name',
      type: 'text'
    }];

    if (!this.create) {
      fields.push({
        title: 'Class.Dialog.Pupil',
        value: this.editableEntity.id,
        type: 'custom',
        comp: 'input-list-component',
        items: this.pupil,
        addListURLParams: {
          type: TargetType.USER,
          client: this.$route.params.client
        },
        addCallback: (user, callbackSuccess, callbackFinally) => {
          Webservice.performRequest(RequestType.POST, {
                type: TargetType.USER,
                client: this.$route.params.client,
                departmentId: this.schoolClassId,
                action: Action.ADD
              }, {userId: user._id}, callbackSuccess,
              error => {
              },
              callbackFinally)
        },
        deleteCallback: (user, callbackSuccess, callbackFinally) => {
          Webservice.performRequest(RequestType.POST, {
                type: TargetType.USER,
                departmentId: this.schoolClassId,
                client: this.$route.params.client,
                action: Action.DELETE
              },
              {userId: user._id}, callbackSuccess, error => {
              }, callbackFinally);
        },
        getSubtitleForElement: (element: User) => {
          if (element.role === UserType.Teacher && element.headTeacher) {
            return this.$t('Class.Dialog.HeadTeacher').toString();
          }
          return this.$t('User.Dialog.Role.' + element.role).toString();
        },
        getIconForElement: (element: User) => {
          return element.role === UserType.Teacher ? 'mdi-school-outline' : 'mdi-account';
        },
        addIcon: 'mdi-account-plus-outline'
      })
    }
    return fields;
  }

  extractUser(): void {
    let users: User[] = [];
    if (!this.create) {
      if (Array.isArray(this.editableEntity.users)) {
        for (const value of this.editableEntity.users as User[]) {
          value.role = UserType.Student;
          users.push(value);
        }
      }
      if (Array.isArray(this.editableEntity.teachers)) {
        for (const value of this.editableEntity.teachers as User[]) {
          value.role = UserType.Teacher;
          users.push(value);
        }
      }
      if (Array.isArray(this.editableEntity.headTeachers)) {
        for (const value of this.editableEntity.headTeachers as User[]) {
          value.headTeacher = true;
          value.role = UserType.Teacher;
          users.push(value);
        }
      }
    }
    this.pupil = users;
  }

  entityPreparation() {
    this.editableEntity.schoolId = this.model.schoolId;
    this.schoolClassId = this.model._id;
    this.extractUser();
  }

  isDirty(): boolean {
    return (this.editableEntity.name !== (this.model?.name || ''));
  }
}
