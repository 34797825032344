import GenericEntity, {GenericWrapper} from "@/assets/Entities/GenericEntity";
import User from "@/assets/Entities/User";

export default class SchoolClass extends GenericEntity{
    name!: string;
    users!: number | User[];
    teachers!: number | User[];
    headTeachers!: User[];
    schoolId!: string;
    countUsers?: number;

    constructor(name?: string) {
        super();
        this.name = name ?? '';
    }
}

export class SchoolClassWrapper extends GenericWrapper<SchoolClass> {
    departments!: SchoolClass[];

    get items(): SchoolClass[] {
        return this.departments;
    }
}
